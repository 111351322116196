const data = {
  currentVolume: 10,
  editions: [
    {
      title: 'Spring 2024',
      volume: 10,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V10n1%20Spring2024%20Spreads_3.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V10n1%20Spring2024%20LowResPages_3.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V10n1%20Spring2024_TOC.pdf',
      pubDate: 'April 5th 2024',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'The Shores Before Us.....The Callings Continue',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/01%20Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Conflict, War, and Terrorism: Sensorimotor Psychotherapy with Civilian Victims of Trauma',
              author: 'Gordon E. MacKinnon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/02%20MacKinnon.pdf'
            },
            {
              title: 'The Relationship Between Leadership Style and Staff Burnout in Healthcare',
              author: 'Thomas O. Matella',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/03%20Matella.pdf'
            },
            {
              title: 'FLOTUS - A New Focus: How the Neurologic Health of the First Ladies of the United States Impacted U.S. History',
              author: 'Sara Heide, Mill Etienne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/04%20Heide.pdf'
            },
            {
              title: 'Salutogenic Reframing as Grounds for Ethical Re-Evaluation of Placebo as a Therapeutic Intervention',
              author: 'M. Heath Patterson, Niko B. Kohls, James Giordano',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/05%20Patterson.pdf'
            },
            {
              title: 'Other D-Days: The World War II Reflections of Patrick H. Maas. An Honored Posthumous Publication',
              author: 'Patrick H. Maas',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/06%20Maas.pdf'
            }
          ]
        },
        {
          title: 'The Open Forum: Exploring The Intersection of Health, Ethics & Law',
          order: 3,
          articles: [
            {
              title: 'The Unprecedented Purposeful Targeting of Health Systems and Hospitals in Wars and Conflicts: An Immediate Call for Global Intervention',
              author: 'Amir Khorram-Manesh, Frederick M. Burkle, Jr.',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/07%20Khorram-Manesh.pdf'
            }
          ]
        },
        {
          title: 'Profiles In Courage: The Next Chapter',
          order: 4,
          articles: [
            {
              title: 'Sailors Dressed Like Soldiers: A Veteran Remembers D-Day',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/08%20Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic\'s Choice',
          order: 5,
          articles: [
            {
              title: 'Book Review: Together: The Healing Power of Connection in a Sometimes Lonely World. A Book by Vivek Murthy',
              author: 'Franklin Eric Wester',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/09%20Wester.pdf'
            },
            {
              title: 'Book Review: Finding Waypoints: A Warrior\'s Journey Toward Peace and Purpose. A Book by Terese Schlachter and Gregory Gadson',
              author: 'Deborah Kenny',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/10%20Kenny.pdf'
            },
            {
              title: 'Book Review: Light One Candle: A Survivor\'s Tale from Lithuania to Jerusalem. A Book by Solly Ganor',
              author: 'Stephanie Taylor, Patrice Shanahan, Patrick DeLeon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/11%20Taylor.pdf'
            },
            {
              title: 'Book Review: The Wounded Healer. A Book by Henri J. M. Nouwen',
              author: 'Joseph Augustine Menna',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/12%20Menna.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'A Reflection: A Historical Reflection: Seaman Warner Lundahl and the Moral Compass',
              author: 'Pietro D. Marghella',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/13%20Marghella.pdf'
            },
            {
              title: 'A Poem: The Unspoken Voice',
              author: 'Susan Rachlin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/14%20Rachlin.pdf'
            },
            {
              title: 'Poem: Number 9',
              author: 'Angela Buzzard',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v10n1/15%20Buzzard.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Spring 2023',
      volume: 9,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V9n1%20Spring2023-SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V9n1%20Spring2023-LowResPagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V9n1%20Spring2023-TOC.pdf',
      pubDate: 'April 2nd 2023',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'The Soul-Journey Before Us All',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/01-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'The Japanese American Incarceration and Its Continuing Effects',
              author: 'Shirley Ann Higuchi, Ray Locker',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/02-Higuchi.pdf'
            },
            {
              title: 'Efficacy of Self-Assessment Interventions During COVID-19 Quarantine',
              author: 'Elizabeth Loika, Joshua C. Watson, Yen Murray',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/03-Loika.pdf'
            },
            {
              title: 'A Local Government Response to COVID-19 and Health Disparities',
              author: 'Charles Brown, Mary I. Lambert, Lauren Franklin, Chastity Sutterfield',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/04-Brown.pdf'
            },
            {
              title: 'COVID-19 Vaccines: Perception and Reality',
              author: 'Maria Benito',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/05-Benito.pdf'
            },
            {
              title: 'Health Care Outcome Disparities: A Cause to Infuse Health Care Curriculum',
              author: 'Akili Nkechi Smith-Washington, Michael A. Washington',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/06-Smith-Washington.pdf'
            }
          ]
        },
        {
          title: 'Profiles In Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'From Army Private to Navy Vice Admiral: Honoring the Life of Vice Admiral John M. Mateczun 1946-2022',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/07-Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic\'s Choice',
          order: 4,
          articles: [
            {
              title: 'Film Review: Till. A Film By Chinonye Chukwu',
              author: 'Ty Young',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/08-Young.pdf'
            },
            {
              title: 'Book Review: The Light We Carry. A Book By Michelle Obama',
              author: 'Janis Riccardelli',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/09-Riccardelli.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'A Poem: Division',
              author: 'Susan Rachlin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/10-Rachlin.pdf'
            },
            {
              title: 'Poem: Uninvited Visitor',
              author: 'Amy Grossblatt Pessah',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v9n1/11-Pessah.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Fall 2022',
      volume: 8,
      number: 2,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n2%20Fall2022-SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n2%20Fall2022-LowResPagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n2%20Fall2022-TOC.pdf',
      pubDate: 'October 24th 2022',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'Breaking The Chains..... Freedom\'s Forever Call',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/01_Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'The Case for Tutuian Ethics of Critical Leadership',
              author: 'Sherman Gillums, Jr.',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/02_Gillums.pdf'
            },
            {
              title: 'The Role of the Creative Arts in Fostering Resistance, Resilience, and Survival: Lessons from the Terezin Holocaust Experience',
              author: 'Gordon E. MacKinnon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/03_MacKinnon.pdf'
            },
            {
              title: 'Exploring Ethical Constructs of the Complementary Roles of Military Healthcare Providers',
              author: 'Owen Sisbarro, Megan K. Applewhite, James Giordano',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/04_Sisbarro.pdf'
            },
            {
              title: 'The Way We Live Now: Civilization and Disease',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/05_Boynton.pdf'
            }
          ]
        },
        {
          title: 'Profiles In Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'Defeating Hate: A Survivor\'s Story.',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/06_Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic\'s Choice',
          order: 4,
          articles: [
            {
              title: 'Book Review: The Choice: Embrace the Possible',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/07_De%20Fischler%20Herman.pdf'
            },
            {
              title: 'Book Review: MAUS I: A Survivor\'s Tale: My Father Bleeds History and MAUS II: A Survivor\'s Tale: And Here My Troubles Began',
              author: 'Julie K. Zadinsky',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/08_Zadinsky.pdf'
            },
            {
              title: 'Book Review: The Light of Days: The Untold Story of Women Resistance Fighters in Hitler\'s Ghettos',
              author: 'Mollie Katz',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/09_Katz.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'A Poem: The Birth of Cruelty',
              author: 'Susan Rachlin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/10_Rachlin.pdf'
            },
            {
              title: 'Poem & Reflection: Gaslighting: Facing a Holocaust Within',
              author: 'Una Hearne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/11_Hearne.pdf'
            },
            {
              title: 'A Creative Reflection: War and a Dozen Roses: A Holocaust of the Mind',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n2/12_Zirkle.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Spring 2022',
      volume: 8,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n1%20Spring2022-SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n1%20Spring2022-LowResPagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V8n1%20Spring2022-TOC.pdf',
      pubDate: 'April 4th 2022',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'Healing\'s Heroes: Learning To Be',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/01_Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Time for a Paradigm Shift: The Necessity for the Human Side of Patient Care',
              author: 'Deborah Kenny',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/02_Kenny.pdf'
            },
            {
              title: 'The Critically Needed Integration of Behavioral Health into Primary Care: A Case Study',
              author: 'Pamela Greene, Kathy Ann Deis',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/03_Greene.pdf'
            },
            {
              title: 'New Pathways for Assisted Psychotherapy: Do Psychedelics Bring Panic or Panacea?',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/04_Boynton.pdf'
            },
            {
              title: 'The School-to-Prison Pipeline',
              author: 'Brenda S. Lamkin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/05_Lamkin.pdf'
            },
            {
              title: 'A Prince Hall Masonic Tradition: Leader and Character Development',
              author: 'Michael A. Washington',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/06_Washington.pdf'
            },
            {
              title: 'Palliation: A Parabolic Pathway',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/07_Gabriele.pdf'
            }
          ]
        },
        {
          title: 'The Open Forum: Exploring the Intersection of Health, Ethics & Law',
          order: 3,
          articles: [
            {
              title: 'A Medical Student\'s Reflection on Implicit Bias and Microaggression in the Hidden Curriculum',
              author: 'Mariah Fontanez-Lutsky',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/08_Fontanez-Lutsky.pdf'
            }
          ]
        },
        {
          title: 'Profiles In Courage: The Next Chapter',
          order: 4,
          articles: [
            {
              title: 'Guest of the Emperor: Rear Admiral Ferdinand V. Berley, MC, USN (ret.)',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/09_Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic\'s Choice',
          order: 5,
          articles: [
            {
              title: 'Film Review: RBG',
              author: 'Erika Gray',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/10_Gray.pdf'
            },
            {
              title: 'Book Review: The Mole and the Violin. The 2013 Original and the 2018 Part II',
              author: 'Christine Nava',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/11_Nava.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'Creative Reflection: Solidarity in Tragedy: A Reflection on the Human Spirit',
              author: 'Jayna Moceri Brooks',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/12_Brooks.pdf'
            },
            {
              title: 'Upon Reflection: Releasing The Inner Child',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v8n1/13_Zirkle.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Fall 2021',
      volume: 7,
      number: 2,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n2%20Fall2021_SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n2%20Fall2021_LowResPagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n2%20Fall2021-TOC.pdf',
      pubDate: "October 7th 2021",
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'First Response',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/01_Gabriele1.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'In Search of Well-Being: Cultural Support for Returning Veterans in the United States and Germany',
              author: 'Clementine Fujimura',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/02_Fujimura.pdf'
            },
            {
                title: 'Care of "Enemy Combatants" in the Wars in Iraq and Afghanistan: Meeting an Involuntary Ethical Demand',
                author: 'Patricia Benner, Jodi Halpern, Catherine A. Chesla, Patricia Watts Kelley',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/03_Benner.pdf'
            },
            {
                title: 'Immunity and Immunopathology of SARS-CoV-2: What Happens During COVID-19 Infection',
                author: 'Maria Benito',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/04_Benito.pdf'
            },
            {
                title: 'Homelessness in America',
                author: 'Rosemary Donley, Jayna Moceri Brooks',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/05_Donley.pdf'
            },
            {
              title: 'A Matter of Courage: Vulnerable Women Prevailing Against the Odds',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/06_Herman1.pdf'
            },
            {
              title: 'Remembering the Sisters of Selma: Exploring the History, Meaning and Significance',
              author: 'Carol K. Coburn',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/07_Coburn.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'A Veteran Remembers December 7, 1941',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/08_Herman2.pdf'
            },
            {
              title: 'Ex Ore Infantium: 9/11 Remembered',
              author: 'Michael Krentz',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/09_Krentz.pdf'
            }
          ]
        },
        {
          title: `The Critic's Choice`,
          order: 4,
          articles: [
            {
              title: 'Film Review: John Lewis: Good Trouble',
              author: 'James A. Cummings',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/10_Cummings.pdf'
            },
            {
              title: 'Book Review: Winning After Losing: Building Resilient Teams',
              author: 'Patricia Hinton Walker',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/11_Walker.pdf'
            },
            {
              title: 'Film Review: Respect',
              author: 'Joseph Menna',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/12_Menna.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'Creative Vignette: Sign Language that Speaks to Your Soul',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/13_Zirkle.pdf'
            },
            {
              title: 'A Closing Reflection: The Hero Experience: Reflecting on the Saint of 9/11, Fr. Mychal Judge, OFM',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n2/14_Gabriele2.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Spring 2021',
      volume: 7,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n1%20Spring2021_SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n1%20Spring2021_LowResPagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V7n1%20Spring2021-TOC.pdf',
      pubDate: "April 4th 2021",
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'Our Common Journey',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/01_Gabriele%20Prelude.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Reclaiming Social Justice and Human Rights: The 1830 Indian Removal Act and the Ethnic Cleansing of Native American Tribes',
              author: 'Nicky Michael, Beverly Jean Smith, William Lowe',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/02_Michael.pdf'
            },
            {
                title: 'The Service Theories and Healing Leadership of Florence Nightingale and Hildegard Peplau: Their Central Importance for Military Psychiatric Nurse Practitioner Education',
                author: 'JoEllen Schimmels, Jouhayna Bajjani-Gebara, Regina Owen, Tarah Lewis, Evelyn Wallace',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/03_Schimmels.pdf'
            },
            {
                title: 'Phytochemicals and Acute Toxicity Study of Ethanol and Acetone Leaf Extract of Vernonia Amygdalina and Psidium GuajavaUsing Albino Wistar Mice',
                author: 'Ekeghalu Elsie Chidinma, Iroha Romanus Ifeanyi, Ajah Patrick Mmaduabuchi, Nwakaeze Emmanuel Amobi',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/04_Chidinma.pdf'
            },
            {
                title: 'Medical Approaches to COVID-19 in The People’s Republic of China: A Cross-Pollination of Traditional and Modern',
                author: 'Jesse Eugene Hoover',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/05_Hoover.pdf'
            },
            {
              title: 'Humanitarian Assistance and Disaster Relief: A Cultural Journey of Applied Hope',
              author: 'Eric Rasmussen',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/06_Rasmussen.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'A Mohawk from Brooklyn',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/07_Herman%20PIC.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 4,
          articles: [
            {
              title: 'Book Review: Susan La Flesche Picotte: A Warrior of the People',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/08_Herman2%20Review.pdf'
            },
            {
              title: 'Book Review: Begin Again. James Baldwin’s America and Its Urgent Lessons for Our Own',
              author: 'Christine Nava',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/09_Nava%20Review.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'Reflection: A Lesson from Our Children',
              author: 'David Lash',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/10_Lash%20Reflection.pdf'
            },
            {
              title: 'Poem: The Experiment',
              author: 'Susan Rachlin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/11_Rachlin%20Poem.pdf'
            },
            {
              title: 'Vignette: Organizing the Idea-Hoarder’s Closet',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v7n1/12_Zirkle%20Vignette.pdf'
            }
          ]
        }
      ]
    },
    {
      title: 'Fall 2020',
      volume: 6,
      number: 3,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n3%20Fall2020-SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n3%20Fall2020-PagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n3%20Fall2020-TOC.pdf',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: '"Re-Membering"',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/01%20Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Where to Begin? Mental Health Issues Associated with COVID-19 and the Needs of Healthcare Providers',
              author: 'Deborah J. Kenny',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/02%20Kenny.pdf'
            },
            {
                title: 'The Public Trust in the Time of COVID-19',
                author: 'Vaughan V. Caines',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/03%20Caines.pdf'
            },
            {
                title: 'Exploring the Phenomenon and Perspectives of Empathy and Compassion in the Caring Professions and Enhancing Resiliency: Part II',
                author: 'Patricia Hinton-Walker, Linda Grabbe, Bonnie Blueford, Gloria Ramsey',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/04%20Walker.pdf'
            },
            {
                title: 'Moral Injury and Harm: An Inevitable Human Experience of War',
                author: 'Patricia Benner, Patricia Watts Kelley',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/05%20Benner.pdf'
            },
            {
              title: 'International Mentorship and Research Ethics and Integrity: Reducing Brain Drain in Africa',
              author: 'Marceline Djuidje-Ngounoue, Pierrette Essama-Mekongo, Judith Torimiro, Cynthia S. Ricard',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/06%20Ngounoue.pdf'
            },
            {
                title: 'The Critical Importance of Certified Nurse-Midwives in Contemporary Healthcare',
                author: 'Lynette Hamlin, Michelle L. Munroe, Patrick H. DeLeon',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/07%20Hamlin.pdf'
            },
            {
                title: 'Harriet Tubman: A First Responder Extraordinaire',
                author: 'Janaé Moore',
                link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/08%20Moore.pdf'
            }
          ]
        },
        {
          title: 'The Open Forum: Exploring the Intersection of Health, Ethics & Law',
          order: 3,
          articles: [
            {
              title: 'A Concern for the Human Race',
              author: 'Jeffrey Omar Patrick',
               link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/09%20Patrick.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 4,
          articles: [
            {
              title: 'Nun, Sailor, Teacher, Priest: The Improbable Career of Moni McIntyre',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/10%20Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 5,
          articles: [
            {
              title: 'Book Review: We’re Better Than This. My Fight for the Future of Our Democracy by Elijah Cummings',
              author: 'Ricky D. Allen',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/11%20Allen.pdf'
            },
            {
              title: 'Film Review: Just Mercy',
              author: 'Brett Cook, Kristin Rice',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/12%20Cook.pdf'
            },
            {
              title: 'Film Review: Hidden Figures',
              author: 'Laura Mateczun',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/13%20Mateczun.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'Reflection: The Cactus Path to Rooting Deep and Soaring High',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/14%20Zirkle.pdf'
            },
            {
              title: 'Vignette: The Cat and I',
              author: 'Brenda S. Lamkin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/15%20Lamkin.pdf'
            }
          ]
        },
        {
          title: 'Special Tribute',
          order: 7,
          articles: [
            {
              title: 'Honoring Jan Herman: The Storyteller of Foggy Bottom',
              author: 'Michael Krentz',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n3/16%20Krentz.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Special Edition 2020",
      volume: 6,
      number: 2,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n2%20SpEdtion2020-SpreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n2%20SpEdtion2020-PagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n2%20SpEdtion2020-TOC.pdf',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'The Nature Never To Forget: Introducing the Special Edition',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/01%20Gabriele.pdf'
            }
          ]
        },
        {
          title: 'The Honored Nature Of Nursing',
          order: 2,
          articles: [
            {
              title: 'Everyday Heroes: Nurses Working Quietly Behind the Scenes Saving Lives and Protecting Their Patients',
              author: 'Deborah J. Kenny, Helen Graham, Angela M. Simmons',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/02%20Kenny.pdf'
            }
          ]
        },
        {
          title: 'Articles & Reports',
          order: 3,
          articles: [
            {
              title: 'Mirroring Military Comradery to Empower Student Veterans Transitioning into Higher Education',
              author: 'Dana Hill, Stephanie Wynn',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/03%20Hill.pdf'
            },
            {
              title: 'Stories from the Academic Front: Leveraging Veteran’s Voices',
              author: 'Amy Stahley, Karen Daley, Rhonda McLain',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/04%20Stahley.pdf'
            },
            {
              title: 'The Transformative Power of Veterans on Campus',
              author: 'Deborah L. Sikes, Rachel R. Whitney, Charity Landrum',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/05%20Sikes.pdf'
            },
            {
              title: 'Veterans Caring for Veterans Through an Academic Practice Partnership',
              author: 'Sandra Oliver-McNeil, Julia Farner, Elizabeth McQuillen',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/06%20Oliver-McNeil.pdf'
            },
            {
              title: 'Seeds of Change: Planting the Building Blocks for Success for Veterans on Campus and in Our Community',
              author: 'Lynette Kennison, Katherine Purcell',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/07%20Kennison.pdf'
            },
            {
              title: 'Translating Military Education and Training to Meet Civilian Academic Requirements',
              author: 'Jené M. Hurlbut, Deborah L. Sikes, Jason E. Saladiner, Patricia G. Francis-Johnson, Imelda R. Revuelto, Melinda Mitchell Jones, Mary Jane Hamilton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/08%20Hurlbut.pdf'
            },
            {
              title: 'Mission Critical: Failure Is Not an Option. The Journey of Three Universities to Promote Military Student Success',
              author: 'Karen Daley, Amy Stahley, Sandra Oliver-McNeil, Sharon Simon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/09%20Daley.pdf'
            },
            {
              title: 'Veterans Coaching Veterans: Transition Into Nursing',
              author: 'Elizabeth Bennett, Patricia Watts Kelley',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/10%20Bennet.pdf'
            },
          ]
        },
        {
          title: 'Postlude',
          order: 4,
          articles: [
            {
              title: 'A Needed Forward Vision',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n2/11%20Boynton.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Spring 2020",
      volume: 6,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n1%20Spring2020-spreads-lowres.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n1%20Spring2020-pages-lowres.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V6n1%20Spring2020-TOC.pdf',
      sections: [
        {
          title: 'From the Editor-in-Chief',
          order: 1,
          articles: [
            {
              title: 'The Needed New',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/01%20Gabriele%20FTEIC.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Resilience: A Necessary Requirement for Health, Well-Being, and Survival for Caring Professionals',
              author: 'Patricia Hinton Walker',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/02%20Hinton%20Walker.pdf'
            },
            {
              title: 'OK Boomer: Ageism Comes of Age in 2020',
              author: 'Michael Krentz',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/03%20Krentz.pdf'
            },
            {
              title: 'The Obesity Epidemic and the Application of Kingdon’s Theory of Multiple Streams',
              author: 'Tanisha Lashae Currie',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/04%20Currie.pdf'
            },
            {
              title: 'Detection of ESBL-Producing E. coli Isolates from Selected Water Sources in Abakaliki, Nigeria',
              author: 'Ekeghalu Elsie Chidinma',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/05%20Chidinma.pdf'
            },
            {
              title: 'Joanna Macy, PhD: The Tireless Voice of a Wise Elder Activist',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/06%20Herman%20De.pdf'
            }
          ]
        },
        {
          title: 'Profiles In Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'Dr. Heimlich’s Maneuvers',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/07%20Herman%20Jan%20PIC.pdf'
            },
            {
              title: 'True Heroes: Honoring Congressman Elijah Cummings and His Nephew, Christopher Cummings',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/08%20Gabriele%20Tributes.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 4,
          articles: [
            {
              title: 'Film Review: Harriet. A Perfect World Pictures film directed by Kasi Lemmons',
              author: 'Janae Moore',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/09%20Moore%20Review.pdf'
            },
            {
              title: 'Book Review: It Shouldn’t Be This Hard to Serve Your Country: Our Broken Government and the Plight of Veterans. By David Shulkin',
              author: 'Moni McIntyre',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/10%20McIntyre%20Review.pdf'
            },
            {
              title: 'Book Review: You Are Worth It: Building a Life Worth Fighting For. By Kyle Carpenter and Don Yaeger',
              author: 'Mill Etienne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/11%20Etienne%20Review.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'A Poem: Mirror Image',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/12%20Gabriele%20Poem.pdf'
            },
            {
              title: 'A Reflective Vignette: How Personal Experiences Can Help Professionals Become Better Practitioners',
              author: 'Maria Benito',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v6n1/13%20Benito%20Vignette.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Fall 2019",
      volume: 5,
      number: 2,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V5n2%20Fall2019-spreadsFINAL.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V5n2%20Fall2019-pagesFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v5n2%20TOC.pdf',
      sections: [
        {
          title: 'From the Editor-in-Chief',
          order: 1,
          articles: [
            {
              title: 'Launching',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/01%20Gabriele.pdf'
            }
          ]
        },
        {
          title: 'New Horizons',
          order: 2,
          articles: [
            {
            title: 'Ragtime, Jazz and the Meaning of Life',
            author: 'Bruce R. Boynton',
            link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/02%20Boynton.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 3,
          articles: [
            {
              title: 'Apollo 11, The Overview Effect and the Psychology of Awe and Self-Transcendence: Considerations for the Human Dimension in Healthcare',
              author: 'Gordon E. MacKinnon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/03%20MacKinnon.pdf'
            },
            {
              title: 'Remembering Stonewall: Its History, Meaning and Impact',
              author: 'David Williams',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/04%20Williams.pdf'
            },
            {
              title: 'Peace at the End-of-Life: What Palliative Care Offers the Seriously-ill African American Patient',
              author: 'Shena Borders Gazaway',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/05%20Gazaway.pdf'
            },
            {
              title: 'Infammation, Friend or Foe? The Incongruity of Infammation or When a Defense Mechanism Turns Sour',
              author: 'Maria Benito',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/06%20Benito.pdf'
            },
            {
              title: 'Clinical Pastoral Education: Insights, Impact and Importance for Medical Education Today',
              author: 'Moni McIntyre',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/07%20McIntyre.pdf'
            },
            {
              title: 'Readying Military Family Nurse Practitioner Students through Operational Training in American Samoa',
              author: 'Jill F. Schramm, Holly Archer, Matthew D’Angelo, Patrick H. DeLeon',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/08%20Schramm.pdf'
            },
            {
              title: 'Other D-Days: The World War II Refections of Patrick H. Maas. An Honored Posthumous Publication',
              author: 'Patrick H. Maas',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/09%20Mass.pdf'
            }
          ]
        },
        {
          title: 'The Open Forum: Exploring the Intersection of Health, Ethics & Law',
          order: 4,
          articles: [
            {
              title: 'Implicit Bias: A scientifcally verifed hegemony or a debunked junk science? A commentary on this epistemology in contemporary Western Society',
              author: 'Vaughan V. Caines',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/10%20Caines.pdf'
            },
            {
              title: 'A Child’s Right, A Litigator’s Paycheck: A Commentary on Child Protection Laws in Bermuda',
              author: 'Marvin Hanna',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/11%20Hanna.pdf'
            },
            {
              title: 'Eliminating Mental Health Disparities in Racial and Ethnic Minorities: A Dire Necessity',
              author: 'Megan M. Tsui',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/12%20Tsui.pdf'
            }
          ]
        },
        {
          title: 'Profles In Courage: The Next Chapter',
          order: 5,
          articles: [
            {
              title: 'Astronaut Joseph Kerwin: America’s First Physician in Space',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/13%20Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 6,
          articles: [
            {
              title: 'Film Review: First Man',
              author: 'Hugh A. Taylor',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/14%20Taylor.pdf'
            },
            {
              title: 'Book Review: Sailing True North. Ten Admirals and the Voyage of Character',
              author: 'Joseph J. Tomas',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/15%20Tomas.pdf'
            },
            {
              title: 'Film Review: Rocketman',
              author: 'Joseph Menna',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/16%20Menna.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 7,
          articles: [
            {
              title: 'Upon Refection: Why Wounded Healers Dive into the Dark',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n2/17%20Zirkle.pdf'
            }
          ]
        },
      ]
    },
    {
      title: "Spring 2019",
      volume: 5,
      number: 1,
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V5n1%20Spring2019pages-FINAL.pdf',
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V5n1%20Spring2019spreads-FINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V5n1%20TOC.pdf',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'Freedom!',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/1-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Davida Coady, MD: Maverick, Organizer, Altruist',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/2-Herman.pdf'
            },
            {
              title: 'Healing through Self-Expression: The Role of Art Therapy in Medicine',
              author: 'Mill Etienne, Adam M. Karp, Jefrey Omar Patrick, Nathan Carberry',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/3-Etienne.pdf'
            },
            {
              title: 'Functional Medicine: A Holistic Treatment Approach to the Peaceful Healing Journey',
              author: 'Pushpanathan Muthuirulan',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/4-Muthuirulan.pdf'
            },
            {
              title: 'Wolf Pack Theory: A Dilemma for Health Care Outcomes',
              author: 'Kathleen Voigt',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/5-Voigt.pdf'
            },
            {
              title: 'Exploring the Phenomenon and Perspectives of Empathy and Compassion in the Caring Professions: Part I',
              author: 'Patricia Hinton Walker, Gloria Ramsey',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/6-Walker.pdf'
            },
            {
              title: 'The Experience of Sabbath: Exploring the Signifcance of Music in Healing from the Interdisciplinary Perspective of Theology, Medicine and the Arts',
              author: 'Joshua D. Arthur',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/7-Arthur.pdf'
            }
          ]
        },
        {
          title: 'The Open Forum: Exploring the Intersection of Health, Ethics & Law',
          order: 3,
          articles: [
            {
              title: 'Gene Editing: A Brave New World?',
              author: 'Vaughan V. Caines',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/8-Caines.pdf'
            },
            {
              title: 'The Need for Balance: Exploring Law and Policy Regarding the Protection of Personal Data',
              author: 'Brett Cook',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/9-Cook.pdf'
            },
            {
              title: 'Compliance versus Moral Behavior in Human Research: The Need for Compassion and Care',
              author: 'Frederick W. Luthardt',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/10-Luthardt.pdf'
            }
          ]
        },
        {
          title: 'Profles In Courage: The Next Chapter',
          order: 4,
          articles: [
            {
              title: 'George Brummell’s Journey. Light for a Darkened Life',
              author: 'Jan K. Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/11-Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 5,
          articles: [
            {
              title: 'Book Review: Becoming',
              author: 'Michael Krentz',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/12-Krentz.pdf'
            },
            {
              title: 'Film Review: On the Basis of Sex',
              author: 'Janae Moore',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/13-Moore.pdf'
            },
            {
              title: 'Film Review: Boy Erased',
              author: 'Hugh Taylor',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/14-Taylor.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'Poem: The Ocean',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/15-Boynton.pdf'
            },
            {
              title: 'Vignette: A Ride to School',
              author: 'Brenda S. Lamkin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/16-Lamkin.pdf'
            },
            {
              title: 'Poem: Self Arrest',
              author: 'Ann Leigh Lucart',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/17-Lucart.pdf'
            },
            {
              title: 'Upon Refection: Ageing in a Timeless World',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v5n1/18-Zirkle.pdf'
            }
          ]
        },
      ]
    },
    {
      title: "Fall 2018",
      volume: 4,
      number: 2,
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n2%20Fall2018pagesFINAL.pdf',
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n2%20Fall2018spreadsFINAL.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n2%20Fall2018-TOC.pdf',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'The Healer Hero: The Call to "Be" as well as to "Do"',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/01-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Everyday Heroes: Nurses Working Quietly Behind the Scenes Saving Lives and Protecting Their Patients',
              author: 'Deborah J. Kenny, Helen Graham, Angela M. Simmons',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/02-Kenny.pdf'
            },
            {
              title: 'Music Therapy: Exercising Music’s Healing Touch',
              author: 'Edgewood R. Warner II, Alan Jeffrey Friedberg, Mill Etienne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/03-Warner.pdf'
            },
            {
              title: 'What is Antibiotic Resistance and Why is it a Problem?',
              author: 'Jere M. Boyer',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/04-Boyer.pdf'
            },
            {
              title: 'The Rescue of Refugees at Sea: Exploring Status Determination, Transport and International Human Rights Law',
              author: 'Brett D. Cook',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/05-Cook.pdf'
            },
            {
              title: 'Look for Me in the Whirlwind: A New Perspective on the Leadership of Joycelyn Elders in Medicine and Healthcare',
              author: 'Michael A. Washington',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/06-Washington.pdf'
            },
            {
              title: 'LeaderBeing: Critical Reflections on Context, Character and Challenge in the Culture of Research and Its Administration',
              author: 'Edward F. Gabriele, Vaughan V. Caines',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/07-GabrieleC.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'Farm Girl to Healing Therapist. The Irrepressible Shirley Godwin',
              author: 'Jan Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/08-Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 4,
          articles: [
            {
              title: 'Film Review: Military Medicine: Beyond the Battlefield. A film hosted and reported by Bob Woodruff',
              author: 'Moni McIntyre',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/09-McIntyre.pdf'
            },
            {
              title: 'Book Review: The Restless Wave: Good Times, Just Causes, Great Fights, and Other Appreciations. By John McCain with Mark Salter',
              author: 'David Mineo',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/10-Mineo.pdf'
            },
            {
              title: 'Film & Book Review: Five Came Back. Book by Mark Harris. Film directed by Laurent Bouzereau',
              author: 'Hugh Taylor',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/11-Taylor.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'A Special Reflection: Nobody Talks to Your Grandfather',
              author: 'David Lash',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/12-Lash.pdf'
            },
            {
              title: 'A Poem and Reflection: From Strangers to Soulmates',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/13-Herman.pdf'
            },
            {
              title: 'Vignette: What Our Patients Teach Us',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/14-Boynton.pdf'
            },
            {
              title: 'Upon Reflection: Learn to Unlearn',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/15-Zirkle.pdf'
            },
            {
              title: 'Poem: Soupline',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n2/16-Gabriele.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Spring 2018",
      volume: 4,
      number: 1,
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n1%20Spring2018-PagesWeb.pdf',
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n1%20Spring2018-spreads.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20V4n1%20Spring2018-TOC.pdf',
      sections: [
        {
          title: 'Prelude',
          order: 1,
          articles: [
            {
              title: 'From Plough to Harvest',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/01_Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'The Evolving State of Health Disparities',
              author: 'Mill Etienne, Edgewood Warner II',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/02_Etienne.pdf'
            },
            {
              title: 'Syrian Refugees\' Wellness in Turkey: A Systematic Review',
              author: 'Danielle Wofford, Ramsey Awad',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/03_Wofford.pdf'
            },
            {
              title: 'Dangerous Grounds: Domestic Unmanned Aerial Systems and the Protection of the Right to Privacy',
              author: 'Brett D. Cook',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/04_Cook.pdf'
            },
            {
              title: '"We Managed Our Healthcare Until They Arrived." Focus on Traditional Medicine in Enugu-Ezike Until 1960',
              author: 'Kelechi Johnmary Ani, Chisom Nkemdilim Urama',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/05_Ani.pdf'
            },
            {
              title: 'Design Thinking: An Innovative Approach to Teaching Ethical Decision-Making for Healthcare Professionals',
              author: 'Gloria Ramsey, Patricia Hinton Walker',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/06_Ramsey.pdf'
            },
            {
              title: 'Parenting Stress in Navy Families: The Importance of Spirituality and Social Support',
              author: 'Abigail Marter Yablonsky, Guofen Yan, Linda Bullock',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/07_Yablonsky.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'The Content of Her Character. Honoring a Veteran for Justice and Peace, Sr. Mary Antona Ebo',
              author: 'Jan Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/08_Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic\'s Choice',
          order: 4,
          articles: [
            {
              title: 'Film Commentary: The Good Fight. James Farmer Remembers The Civil Rights Movement',
              author: 'Val Coleman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/09_Coleman.pdf'
            },
            {
              title: 'Book Review: The Choice: Embrace the Possible. A Memoir by Dr. Edith Eva Eger',
              author: 'De Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/10_De%20Herman.pdf'
            },
            {
              title: 'Film Review: Moonlight',
              author: 'Moni McIntyre',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/11_McIntyre.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 5,
          articles: [
            {
              title: 'The Fields and Oceans of the Novice Healer: A Creative Dialogue',
              author: 'Gregory Mabry',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/12_Mabry.pdf'
            },
            {
              title: 'I Speak To Your Spirit Now: A Poem',
              author: 'Janae Moore',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/13_Moore.pdf'
            },
            {
              title: 'From Panic to Prana: A Vignette',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v4n1/14_Zirkle.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Fall 2017",
      volume: 3,
      number: 2,
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n2%20singles.pdf',
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n2%20spreads.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n2%20TOC.pdf',
      sections: [
        {
          title: 'From the Editor-In-Chief',
          order: 1,
          articles: [
            {
              title: 'From the Editor-In-Chief',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/01-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'A Time for a Paradigm Shift? The Necessity for the Human Side of Patient Care',
              author: 'Deborah Kenny',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/02-Kenny.pdf'
            },
            {
              title: 'Zika Virus: What Can We Learn',
              author: 'Jere M. Boyer',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/03-Boyer.pdf'
            },
            {
              title: 'Function Creep: Investigating the Effects of the Subjugation of Scientific Innovation and Its Moral Applicability and Potential Consequences',
              author: 'Vaughan Caines',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/04-Caines.pdf'
            },
            {
              title: 'Cultural and Structural Violence in the Lives of Syrian Refugees',
              author: 'Tod Schneider, James Shraiky, Danielle Woford, Ramsey Awad',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/05-Schneider.pdf'
            },
            {
              title: 'Reform and Renewal in a Post-Luther World',
              author: 'Moni McIntyre',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/06-McIntyre.pdf'
            },
            {
              title: 'Palliation: A Parabolic Pathway',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/07-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 3,
          articles: [
            {
              title: 'Dr. Frederick “Skip” Burkle. A Physician for All Seasons',
              author: 'Jan Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/08-HermanJan.pdf'
            }
          ]
        },
        {
          title: 'Vignettes',
          order: 4,
          articles: [
            {
              title: 'Waking the Mermaid',
              author: 'Katherine L. Sparrow',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/09-Sparrow.pdf'
            },
            {
              title: 'Sleep Eating Syndrome: My Recovery',
              author: 'Michele Savaunah Zirkle',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/10-Zirkle.pdf'
            },
            {
              title: 'A Miracle',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/11-Boynton.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 5,
          articles: [
            {
              title: 'Book Review: Carry On. A Story of Resilience, Redemption and an Unlikely Family',
              author: 'Tyrell Tysheen Yorke, Chloe Whittington Yorke',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/12-Yorke.pdf'
            },
            {
              title: 'Book Review: The Spirit Catches You and You Fall Down: A Hmong Child, Her American Doctors, and the Collision of Two Cultures',
              author: 'David Aharanof, Mill Etienne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/13-Aharanof.pdf'
            },
            {
              title: 'Film Review: Hidden Figures',
              author: 'Laura Mateczun',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/14-Mateczun.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'The Flood and the Rainbow: A Poem and Refection',
              author: 'De Fischler Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n2/12-HermanDe.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Spring 2017",
      volume: 3,
      number: 1,
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n1%20Spring%202017%20Singles.pdf',
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n1%20Spring%202017%20Spreads.pdf',
      toc: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v3n1%20Spring%202017%20TOC.pdf',
      sections: [
        {
          title: 'From the Editor-In-Chief',
          order: 1,
          articles: [
            {
              title: 'Meaning’s Mirror: The Impressionistic Journey',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/1-Gabriele%20FTEIC.pdf'
            }
          ]
        },
        {
          title: 'Articles & Commentaries',
          order: 2,
          articles: [
            {
              title: 'Starting From Zero: An Exploration of Contemporary Issues in Haiti',
              author: 'Tod Schneider, James Shraiky, Wendy Patchin',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/2-Schneider.pdf'
            },
            {
              title: 'The Transformative Richness of Puerto Rican and Caribbean Cultural Diversity in Health Care and Spirituality',
              author: 'Héctor E. López-Sierra',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/3-Lo%CC%81pez-Sierra.pdf'
            },
            {
              title: 'Knowledge, Attitude and Practice of Cervical Cancer Screening Services among Rural Women in Ohaukwu Local Government Area of Ebonyi State, Nigeria',
              author: 'Kelechi Johnmary Ani, Rita Ihuoma Anaba, Peter Chukwunwendu Okeke',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/4-Ani.pdf'
            },
            {
              title: 'Zika Virus: Challenges and Considerations',
              author: 'Christie Joya',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/5-Joya.pdf'
            },
            {
              title: 'Empathy and Dedication: Hospital Corpsmen to Physician Assistants – A Wasted Talent Pool in a Time of Need',
              author: 'David Lash',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/6-Lash.pdf'
            },
            {
              title: 'An Inquiry into the Compliance of Construction to Health and Safety Regulations in Imo State, Nigeria',
              author: 'Emmanuel Ifeanyichukwu Nkeleme, Andrew Obinna Nwaubani, Ijeoma Genevieve Anikelechi',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/7-Nkeleme.pdf'
            },
            {
              title: 'Anthropological Synthesis of Spirituality and Pain Management: How Spirituality Affects Pain Outcomes and Copings',
              author: 'Andrew A. Ovienloba',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/8-Ovienloba.pdf'
            },
            {
              title: 'A Clamor in the Market Place: Meaning and Discourse in the Commerce of Healthcare and Research Institutions',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/9-Gabriele.pdf'
            }
          ]
        },
        {
          title: 'Vignettes',
          order: 3,
          articles: [
            {
              title: 'Healing Hands',
              author: 'Michelle Savaunah Zirkel Marcum',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/10-Marcum.pdf'
            },
            {
              title: 'The Art of Diagnosis',
              author: 'Bruce R. Boynton',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/11-Boynton.pdf'
            },
            {
              title: 'Time for Prayerful Rounds',
              author: 'Charmagne G. Beckett',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/12-Beckett.pdf'
            },
            {
              title: 'Mumbo-Jumbo',
              author: 'Jesse Eugene Hoover',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/13-Hoover.pdf'
            }
          ]
        },
        {
          title: 'Profiles in Courage: The Next Chapter',
          order: 4,
          articles: [
            {
              title: '"Dr. Schweitzer, I Presume?" The Life and Times of Fergus and Ruth Pope',
              author: 'Jan Herman',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/14-Herman.pdf'
            }
          ]
        },
        {
          title: 'The Critic’s Choice',
          order: 5,
          articles: [
            {
              title: 'Book Review: Musicophilia: Tales of Music and the Brain',
              author: 'Nathan Carberry, Mill Etienne',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/15-Carberry.pdf'
            },
            {
              title: 'Book Review: Just Mercy. A Story of Justice and Redemption',
              author: 'Nickolas L. Rapley',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/16-Rapley.pdf'
            }
          ]
        },
        {
          title: 'Under City Lights',
          order: 6,
          articles: [
            {
              title: 'Poem and Reflection: Graduation',
              author: 'Gregory C. Mabry',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/17-Mabry.pdf'
            },
            {
              title: 'Short Story: The Ticket Taker',
              author: 'Katherine L. Sparrow',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/18-Sparrow.pdf'
            },
            {
              title: 'A Parable: "Honk….if you love"',
              author: 'Edward F. Gabriele',
              link: 'https://jhhe.sempervifoundation.org/pdfs/v3n1/19-Gabriele%20UCL.pdf'
            }
          ]
        }
      ]
    },
    {
      title: "Spring 2016",
      volume: 2,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v2n1%20Spring%202016%20Spreads.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20v2n1%20Spring%20Pages%202016.pdf'
    },
    {
      title: "Fall 2015",
      volume: 1,
      number: 2,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20Vol%201%20No%202%20Fall%202015%20spreads.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20Vol%201%20No%202%20Fall%202015.pdf'
    },
    {
      title: "Spring 2015",
      volume: 1,
      number: 1,
      spreads: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20Vol%201%20No%201%20Spring%202015%20Spreads.pdf',
      fullPage: 'https://jhhe.sempervifoundation.org/pdfs/JHHE%20Vol%201%20No%201%20Spring%202015.pdf'
    }
  ]
}

export default data